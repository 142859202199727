exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audit-js": () => import("./../../../src/pages/audit.js" /* webpackChunkName: "component---src-pages-audit-js" */),
  "component---src-pages-deposit-js": () => import("./../../../src/pages/deposit.js" /* webpackChunkName: "component---src-pages-deposit-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-sacredshardstaking-js": () => import("./../../../src/pages/sacredshardstaking.js" /* webpackChunkName: "component---src-pages-sacredshardstaking-js" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-syndicate-js": () => import("./../../../src/pages/syndicate.js" /* webpackChunkName: "component---src-pages-syndicate-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

