//CounterReducer.js
const initialState = { show_mint: false };
export const web3InitialState = {
    provider: null,
    web3Provider: null,
    address: null,
    network: null,
    connect: null,
    disconnect: null,
    show_mint: false,
    isOkx: false
  }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MINT_ENABLED': return {
            ...state,
            show_mint: true
        }
        case 'RESET' : return {
            ...state,
            counterData: 0
        }
        case 'PROVIDER' : return {
            ...state,
            provider: action.payload
        }
        case 'WEB3PROVIDER' : return {
            ...state,
            provider: action.provider,
            web3Provider: action.web3Provider,
            address: action.address,
            network: action.network,
        }
        case 'WEB3OKX': return {
            ...state,
            address: action.address,
            web3Provider: null, // Set web3Provider to null since it's not used
            network: null, // Set network to null since it's not used
            isOkx: action.isOkx,
        }
            case 'RESET_WEB3_PROVIDER':
                return web3InitialState
            default: return state
            }
        }
export default reducer;